import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/app/App';
import reportWebVitals from './monitoring/reportWebVitals';

import './css/index.css';
import 'semantic-ui-less/semantic.less'

import './i11n/i18n';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers'

import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);



const store = createStore(reducers, applyMiddleware(reduxThunk));

// window.LOG_LEVEL = 'DEBUG';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={ store }>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
