import React, { useEffect, useState } from 'react'
import Amplify from 'aws-amplify'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Login } from '../login/Login';
import { Home } from '../home/home';

// import awsExports from '../../aws-exports';

// Amplify.configure(awsExports);

const App = () => {
    const [authState, setAuthState] = useState<{ state: AuthState, user: object | undefined }>({
        state: AuthState.SignedOut,
        user: undefined
    });

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState({ state: nextAuthState, user: authData });
        });
    }, []);

    return authState.state === AuthState.SignedIn && authState.user ? (
        <Home/>
    ) : (
        <Login/>
    )
}

export default App;








