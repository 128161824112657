// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CaseStatus = {
  "DECISION_AVAILABLE": "DecisionAvailable",
  "NOT_COMPLETE": "NotComplete",
  "ARCHIVED": "Archived"
};

const LoanFileStatus = {
  "IMPORTED": "Imported",
  "IN_PROGRESS": "InProgress",
  "ERROR": "Error"
};

const CreditReportStatus = {
  "IMPORTED": "Imported",
  "IN_PROGRESS": "InProgress",
  "ERROR": "Error"
};

const AusDecisionStatus = {
  "IN_PROGRESS": "InProgress",
  "COMPLETE": "Complete",
  "ERROR": "Error"
};

const UserGroup = {
  "ADMIN": "Admin",
  "COUNSELOR": "Counselor",
  "LENDER": "Lender"
};

const Program = {
  "SONYMA": "SONYMA",
  "SONYMA_GIVE_US_CREDIT": "SONYMA_GIVE_US_CREDIT"
};

const { Organization, CognitoUser, Asset, IncomeItem, Borrower, LoanFile, CreditReport, AusDecision, Case, SONYMACaseDetails, StepResult } = initSchema(schema);

export {
  Organization,
  CognitoUser,
  Asset,
  IncomeItem,
  Borrower,
  LoanFile,
  CreditReport,
  AusDecision,
  Case,
  SONYMACaseDetails,
  CaseStatus,
  LoanFileStatus,
  CreditReportStatus,
  AusDecisionStatus,
  UserGroup,
  Program,
  StepResult
};