import { GET_LOAN_FILES, UPDATE_LOAN_FILE_LIST, SET_LOAN_FILE_LIST } from '../actions/types';
import _ from 'lodash';
const initState = {
    loanFiles : {}
}
export const loanfiles = (state:any = initState, action:any) =>{
    
    switch(action.type){
        case GET_LOAN_FILES:
            return { ...state, loanFiles: state.loanFiles };
        case SET_LOAN_FILE_LIST: 
            return { ...state,loanFiles: action.payload };
            //const newFiles  = { ...state.loanFiles, ..._.mapKeys(action.payload, 'id') };
            //return  action.payload;
        case UPDATE_LOAN_FILE_LIST:
            
            let newLoanFiles =  state.loanFiles;
            newLoanFiles.push(action.payload);
            return { ...state,loanFiles: newLoanFiles };
        default:
            return state;
    }
}