import React from 'react';
import { useEffect, useState } from 'react';
import { Auth, DataStore } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { CognitoUser } from '../models';
import { Header } from 'semantic-ui-react';

export const CurrentOrganization = () => {
  const [userName, setUserName] = useState('');
  const [organization, setOrganization] = useState('');

  const getOrganization = async (userName: string) => {
    const cognitoUser = await DataStore.query(CognitoUser, (u) =>
      u.userName('eq', userName)
    );
    setOrganization(cognitoUser?.[0]?.organization?.name || '');
  };

  const fetchOrganization = (userName: string) => {
    if (!userName) return;
    // const user = await Auth.currentAuthenticatedUser();
    return DataStore.observeQuery(CognitoUser, (u) =>
      u.userName('eq', userName)
    ).subscribe((snapshot) => {
      const { isSynced, items } = snapshot;
      if (isSynced) {
        // noinspection JSIgnoredPromiseFromCall
        getOrganization(userName);
      }
    });
  };

  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUserName(user.getUsername());
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchUser();
  }, []);

  useEffect(() => {
    const subscription = fetchOrganization(userName);
    return () => subscription && subscription.unsubscribe();
  }, [userName]);

  return (
    <Header.Subheader>
      <b>{organization}</b>
    </Header.Subheader>
  );
};
